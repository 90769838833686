@use '../../styles/constants.scss' as const;

.webMenu {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;
  height: 72px;
  display: flex;
  justify-content: center;
  z-index: 50;

  @media screen and (max-width: #{const.$XS_MAX}) {
    display: none;
  }

  .container {
    width: 100%;
    max-width: 1400px;
    margin: 0 20px;
    height: 100%;
    display: flex;
    justify-content: center;
    border-radius: 0 0 20px 20px;
    background: rgba(255, 252, 243, 0.3);
    filter: blur(0);
    backdrop-filter: blur(3px);

    .content {
      width: 70%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;

      @media screen and (max-width: #{const.$SM_MAX}) {
        width: 90%;
      }

      .items {
        position: relative;
        color: const.$RED;
        font-size: 15px;
        letter-spacing: 0.1em;
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 16px;

        &:hover {
          .circleBox {
            .circle {
              animation: circle-hover 0.4s forwards;
            }
          }
        }

        .circleBox {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          width: 22px;
          height: 22px;
          left: 8px;
          bottom: 8px;
          overflow: hidden;

          .circle {
            position: relative;
            width: 18px;
            height: 9px;
            border: solid 1px const.$RED;
            background-color: transparent;
            border-radius: 0 0 100vh 100vh;
            border-top: none;
            margin-bottom: 12px;
            transform: rotate(180deg);
            transform-origin: top center;
            animation: circle-hoverOff 0.4s ease-in forwards;

            @keyframes circle-hover {
              0% {
                transform: rotate(180deg);
              }
              100% {
                transform: rotate(30deg);
              }
            }

            @keyframes circle-hoverOff {
              0% {
                transform: rotate(30deg);
              }
              100% {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
  }
}

.mobileMenu {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  user-select: none;
  display: flex;
  align-items: center;
  height: 67px;
  padding-left: 16px;
  background: rgba(255, 252, 243, 0.3);
  filter: blur(0);
  backdrop-filter: blur(3px);
  z-index: 50;

  &.hideBlur {
    background-color: transparent;
    filter: none;
    backdrop-filter: none;
  }

  &.showMenuStyle {
    z-index: 999;
    background-color: transparent;
  }

  @media screen and (min-width: #{const.$XS_MAX + 1}) {
    display: none;
  }

  .hamburgerButton {
    cursor: pointer;
    position: relative;
    height: 32px;
    width: 32px;

    .line {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background: #fff;
      text-align: center;
      border-radius: 100vh;
    }
    .line01 {
      top: 0;
      transition: 0.4s ease;
    }
    .line02 {
      top: 15px;
      transition: 0.4s ease;
    }
    .line03 {
      top: 30px;
      transition: 0.4s ease;
    }
    .red {
      background: const.$RED;
    }

    .buttonLine01 {
      background: #fff;
      transform: translateY(15px) rotate(-45deg);
    }
    .buttonLine02 {
      background: #fff;
      opacity: 0;
      animation: active-menu-bar02 0.8s forwards;
    }
    @keyframes active-menu-bar02 {
      100% {
        left: 80%;
      }
    }
    .buttonLine03 {
      background: #fff;
      transform: translateY(-15px) rotate(45deg);
    }
  }
}

.overlayMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 998;
  background-image: url('../../assets/background.png');
  background-repeat: no-repeat;
  transform-origin: top left;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    width: 100%;
    max-height: 500px;
    margin-top: 70px;
    padding: 0 24px;

    .items {
      padding: 20px;
      color: const.$WHITE;
      font-size: 15px;
      font-family: 'Inter', 'Noto Sans JP', 'メイリオ';
    }
  }
}
