@use '../../styles/constants.scss' as const;

.container {
  @include const.MainContainer();

  .content {
    @include const.Content;

    ul {
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      gap: 32px 0;
      margin-bottom: 200px;

      @media screen and (max-width: #{const.$XS_MAX}) {
        margin-top: 24px;
        margin-bottom: 56px;
        gap: 56px 0;
      }

      li {
        display: flex;
        color: const.$BROWN;
        transition: 0.3s ease;
        height: 192px;

        @media screen and (max-width: #{const.$XS_MAX}) {
          display: block;
          text-align: center;
          height: 100%;
        }

        img {
          width: 100%;
          max-width: 256px;
          aspect-ratio: 4/3;
          object-fit: cover;
          border-radius: 8px;
          background-color: #ded1d1;
        }

        .newsBox {
          margin-left: 24px;
          text-align: start;

          @media screen and (max-width: #{const.$XS_MAX}) {
            margin-left: 0;
            margin-top: 24px;
          }

          h3 {
            @include const.maxTextLines(2);
            word-break: break-all;
            font-weight: normal;
            font-size: 20px;
            margin-bottom: 24px;

            @media screen and (max-width: #{const.$XS_MAX}) {
              font-size: 15px;
            }
          }

          .description {
            @include const.maxTextLines(4);
            word-break: break-all;
            line-height: 30px;

            @media screen and (max-width: #{const.$XS_MAX}) {
              margin-top: 16px;
              line-height: 18px;
              font-size: 10px;
            }

            p {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
