body {
  padding: 0;
  margin: 0;
  font-family: 'Noto Sans JP', 'メイリオ';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  background-image: url('../assets/background.png');
  background-size: cover;
  background-attachment: fixed;
  background-color: #fffcf3 !important;
  font-size: 13px;
  letter-spacing: 0.1em;

  input:-webkit-autofill {
    box-shadow: 0 0 0 1000px white inset !important;
  }
}

a {
  text-decoration: none;
}

ul {
  padding-left: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

img {
  vertical-align: bottom;
  pointer-events: none;
}

.grecaptcha-badge {
  visibility: hidden;
}
