@use '../../styles/constants.scss' as const;

.container {
  @include const.MainContainer();

  .content {
    @include const.Content;

    .memberArea {
      display: flex;
      margin-top: 100px;
      height: 400px;
      gap: 0 26px;

      @media screen and (max-width: #{const.$SM_MAX}) {
        display: block;
        margin-top: 24px;
        height: 100%;
      }

      .memberBox1 {
        display: flex;
        align-items: center;
        height: 100%;
        gap: 0 26px;

        @media screen and (max-width: #{const.$SM_MAX}) {
          display: block;
          text-align: center;
          margin-bottom: 21px;
        }

        .memberSelectBox {
          display: flex;
          flex-direction: column;
          gap: 16px 0;

          @media screen and (max-width: #{const.$SM_MAX}) {
            flex-direction: row;
            justify-content: center;
            gap: 0 2vw;
            margin-bottom: 21px;
          }

          .memberImage {
            width: 70px;
            height: 70px;
            transition: 0.3s ease-out;

            &:hover {
              @media screen and (pointer: fine) {
                opacity: 0.8;
              }
            }

            img {
              width: 100%;
              height: 100%;
              border-radius: 8px;
              object-fit: contain;
            }
          }
        }

        .profileImage {
          width: 400px;
          height: 400px;
          border-radius: 8px;
          object-fit: cover;
          user-select: none;

          @media screen and (max-width: #{const.$SM_MAX}) {
            width: 100%;
            max-width: 400px;
            height: 100%;
            max-height: 400px;
          }
        }
      }

      .memberBox2 {
        display: flex;
        align-items: center;
        height: 100%;
        gap: 0 26px;

        img {
          width: 50px;

          @media screen and (max-width: #{const.$SM_MAX}) {
            display: none;
          }
        }

        .border {
          width: 4px;
          min-width: 4px;
          height: 100%;
          border-radius: 100vh;
          background-color: const.$BROWN;

          @media screen and (max-width: #{const.$SM_MAX}) {
            display: none;
          }
        }

        .profileBox {
          color: const.$BROWN;
          white-space: pre-line;
          line-height: 30px;
          letter-spacing: 0.1em;
          margin-bottom: 56px;

          h2 {
            font-weight: normal;
            margin: 0;
          }
        }
      }
    }
  }
}
