@use '../../styles/constants.scss' as const;

.chatBallon {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  img {
    width: 28px;
  }

  .arrowBox {
    position: relative;
    width: 100%;
    background: const.$WHITE;
    padding: 8px 25px;
    margin-left: 25px;
    text-align: left;
    border: 1px solid #d0b2b2;
    color: const.$BROWN;
    border-radius: 100vh;
    font-size: 15px;
    -webkit-border-radius: 100vh;
    -moz-border-radius: 100vh;

    @media screen and (max-width: #{const.$XS_MAX}) {
      font-size: 10px;
    }

    &:after,
    &:before {
      content: '';
      border: solid transparent;
      height: 0;
      width: 0;
      pointer-events: none;
      position: absolute;
      right: 100%;
      top: 50%;
    }

    &:before {
      border-color: rgba(208, 178, 178, 0);
      border-top-width: 2.5px;
      border-bottom-width: 3px;
      border-left-width: 0px;
      border-right-width: 10px;
      margin-top: -5px;
      margin-right: 0px;
      border-right-color: #d0b2b2;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-top-width: 2px;
      border-bottom-width: 2px;
      border-left-width: 18px;
      border-right-width: 10px;
      margin-top: -4.5px;
      margin-right: -2px;
      border-right-color: const.$WHITE;
    }
  }
}
