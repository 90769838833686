@use '../../styles/constants.scss' as const;

.container {
  @include const.MainContainer();

  .content {
    @include const.Content;

    ul {
      display: grid;
      grid-template-columns: repeat(3, minmax(245px, 1fr));
      margin-top: 40px;
      gap: 28px;
      margin-bottom: 200px;

      @media screen and (max-width: #{const.$SM_MAX}) {
        grid-template-columns: repeat(2, minmax(170px, 1fr));
      }

      @media screen and (max-width: #{const.$XS_MAX}) {
        margin-top: 24px;
        margin-bottom: 56px;
        gap: 3px;
      }

      li {
        position: relative;
        display: flex;
        align-items: center;
        border: solid 1px #d0b2b2;
        box-sizing: border-box;
        border-radius: 8px;

        &:hover {
          @media screen and (pointer: fine) {
            img {
              transform: scale(1.1);
            }
          }
        }

        .imageBox {
          position: relative;
          width: 140px;
          height: 140px;
          min-width: 140px;
          min-height: 140px;
          border-radius: 7px 0 0 7px;
          object-fit: cover;
          transition: 1s linear;
          overflow: hidden;

          @media screen and (max-width: #{const.$XS_MAX}) {
            width: 70px;
            height: 70px;
            min-width: 70px;
            min-height: 70px;
          }

          img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            transition: 0.3s;
            width: 100%;
            height: 100%;
          }
        }

        p {
          margin: 0 7px;
          color: const.$BROWN;

          @media screen and (max-width: #{const.$XS_MAX}) {
            font-size: 10px;
          }
        }
      }
    }
  }
}
