@use '../../styles/constants.scss' as const;

.layoutContainer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;

  .container {
    display: flex;
    justify-content: center;
  }

  .copyright {
    display: flex;
    justify-content: center;
    margin-top: 130px;
    padding-bottom: 40px;
    color: const.$WHITE;
  }
}
