@use '../../styles/constants.scss' as const;

.container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/background.png');
  background-size: cover;

  @media screen and (max-width: #{const.$XS_MAX}) {
    background-image: url('../../assets/background_mobile.png');
  }

  .content {
    width: 100%;
    max-width: 1300px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 20px;

    @media screen and (max-width: #{const.$XS_MAX}) {
      display: block;
      justify-content: center;
      text-align: center;
    }

    p {
      font-size: 20px;
      font-family: 'Inter', 'Noto Sans JP', 'メイリオ';
      color: const.$WHITE;

      @media screen and (max-width: #{const.$SM_MAX}) {
        font-size: 13px;
      }
    }

    img {
      width: 50vw;
      height: 50vw;
      max-width: 730px;
      max-height: 730px;
      object-fit: cover;
      z-index: 99;
      margin-top: 70px;
      user-select: none;

      @media screen and (max-width: #{const.$XS_MAX}) {
        width: 100%;
        height: 100%;
        max-width: 300px;
        max-height: 300px;
        margin-top: 30px;
      }
    }
  }
}
