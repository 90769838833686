/* Break Points */
$LG_MAX: 1920px;
$MD_MAX: 1280px;
$SM_MAX: 960px;
$XS_MAX: 640px;
$XXS_MAX: 480px;

/* Colors */
$WHITE: #ffffff;
$DARK: #262222;
$RED: #eb442a;
$IVORY: #fffcf3;
$BROWN: #714a44;
$WOOD: #c9bebc;

@mixin MainContainer() {
  position: relative;
  background-color: $IVORY;
  width: 100%;
  max-width: 1400px;
  min-height: 888px;
  border-radius: 0 0 50px 50px;
}

@mixin Content {
  margin: 0 60px;
  margin-top: 72px;

  @media screen and (max-width: #{$XS_MAX}) {
    margin: 16px;
  }
}

//　文字の最大表示行数
@mixin maxTextLines($lineCount) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lineCount;
  overflow: hidden;
}
