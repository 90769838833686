@use '../../styles/constants.scss' as const;

.container {
  @include const.MainContainer();

  .content {
    @include const.Content;

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 40px;
      margin-bottom: 200px;

      @media screen and (max-width: #{const.$XS_MAX}) {
        margin-top: 24px;
        margin-bottom: 56px;
      }

      ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 800px;
        margin: 0;
        gap: 18px 0;

        li {
          display: inline-flex;
          flex-direction: column;

          label {
            color: const.$BROWN;
          }

          input[type='text'] {
            width: 100%;
            height: 40px;
            border: solid 1px const.$BROWN;
            background-color: #fff;
            color: const.$DARK;
            padding-left: 5px;
            outline: none;
            border-radius: 8px;
            box-sizing: border-box;
            font-family: inherit;

            &::placeholder {
              color: const.$WOOD;
              padding-left: 5px;
              opacity: 0.7;
            }

            &:focus {
              ::placeholder {
                color: const.$BROWN;
              }
            }
          }

          textarea {
            padding: 5px;
            border: solid 1px const.$BROWN;
            background-color: #fff;
            color: const.$DARK;
            outline: none;
            border-radius: 8px;
            font-family: inherit;

            &::placeholder {
              color: const.$WOOD;
              padding-left: 5px;
              opacity: 0.7;
            }

            &:focus::placeholder {
              color: const.$BROWN;
            }
          }
        }
      }

      input[type='submit'] {
        position: relative;
        cursor: pointer;
        width: 100%;
        max-width: 800px;
        height: 40px;
        background-color: #e9573f;
        border: none;
        transition: 0.3s ease-out;
        user-select: none;
        box-sizing: border-box;
        border-radius: 8px;
        color: const.$IVORY;
        margin-top: 45px;
        z-index: 1;
        appearance: none;
        -webkit-appearance: none;

        &:hover {
          @media screen and (pointer: fine) {
            opacity: 0.8;
          }
        }

        &:disabled {
          cursor: default;

          &:hover {
            opacity: 1;
          }
        }
      }

      span,
      .errorText {
        color: #e91a58;
        font-size: 10px;
      }
      .recapture {
        color: const.$BROWN;
        font-size: 10px;
        margin: 0;

        a {
          color: #0683de;
        }
      }
    }
  }
}
