@use '../../styles/constants.scss' as const;

.container {
  @include const.MainContainer();

  .content {
    @include const.Content;

    .infomationArea {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 100px;

      @media screen and (max-width: #{const.$XS_MAX}) {
        margin-top: 24px;
        height: 100%;
      }

      ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 800px;
        margin: 0;
        gap: 16px 0;

        li {
          display: flex;

          label {
            display: flex;
            align-items: center;
            width: 20%;
            padding: 10px 0 10px 32px;
            background-color: #e9573f;
            border-radius: 8px 0 0 8px;
            color: const.$IVORY;

            @media screen and (max-width: #{const.$XS_MAX}) {
              font-size: 10px;
              padding: 6px 0 6px 16px;
            }
          }

          p {
            display: flex;
            align-items: center;
            width: 80%;
            margin: 0;
            padding: 10px 32px;
            border-radius: 0 8px 8px 0;
            background-color: #fbf2ea;
            color: const.$BROWN;

            @media screen and (max-width: #{const.$XS_MAX}) {
              font-size: 10px;
              padding: 6px 16px;
            }
          }
        }
      }
    }
  }
}
