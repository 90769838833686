@use '../../styles/constants.scss' as const;

.container {
  @include const.MainContainer();

  .content {
    @include const.Content;

    .notFoundArea {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      margin-bottom: 200px;

      @media screen and (max-width: #{const.$XS_MAX}) {
        margin-top: 24px;
        margin-bottom: 56px;
        height: 100%;
      }

      a {
        text-decoration: underline;
        font-size: 1.2rem;
        color: const.$BROWN;
      }

      img {
        width: 100%;
        max-width: 400px;
        object-fit: cover;
        transform: rotate(3deg);
      }
    }
  }
}
