@use '../../styles/constants.scss' as const;

.title {
  @media screen and (max-width: #{const.$XS_MAX}) {
    position: fixed;
    top: 0;
    left: 0;
    height: 67px;
    z-index: 50;
    margin-left: 63px;
    display: flex;
    align-items: center;
    user-select: none;
  }

  img {
    height: 59.5px;
    margin-bottom: 24px;

    @media screen and (max-width: #{const.$XS_MAX}) {
      height: 20px;
      margin-bottom: 0;
    }
  }
}

.balloon {
  @media screen and (max-width: #{const.$XS_MAX}) {
    margin-top: 67px;
  }
}
